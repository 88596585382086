import React from "react"
import { colors } from "../utils/presets"
import { options } from "../utils/typography"

const FuturaParagraph = ({ children }) => (
  <p
    css={{
      fontFamily: options.headerFontFamily.join(`,`),
      fontSize: `1.75rem`,
      marginBottom: 0,
      color: colors.lemon,
    }}
  >
    {children}
  </p>
)

export default FuturaParagraph
